<template>
    <div>
        <template v-if="cart.length > 0">
            <div style="min-height: calc(100vh - 253px)">
                <v-container>
                    <v-row v-for="(item,i) in cart" :key="'item-'+i" class="py-12" v-bind:class="{'ultimo-objeto-en-cesta':i+1 != cart.length}">
                        <v-col cols="auto">
                            <v-img :src="'https://content.elmurcielagotequileria.com/'+item.presentacion.producto.cover.path" width="150"></v-img>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <h2>{{item.presentacion.producto.nombre}}</h2>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span class="bold">Clase</span> {{item.presentacion.producto.clase.name}}
                                </v-col>
                                <v-col>
                                    <span class="bold">Cont. Neto</span> {{item.presentacion.contenido}}
                                </v-col>
                                <v-col>
                                    <span class="bold">Cantidad</span> {{item.cantidad}}
                                </v-col>
                                <v-col>
                                    <span class="bold">Precio</span> ${{numberWithCommas(item.presentacion.precio)}}
                                </v-col>
                                <v-col>
                                    <span class="bold">Total</span> ${{numberWithCommas(item.cantidad * item.presentacion.precio)}}
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col>
                                    <span class="pointer d-inline-block" @click="removeItem(i)">Eliminar producto</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div style="background-color: #EFECE8">
                <v-container>
                    <v-row align="center">
                        <v-col cols="12" class="text-center pb-0">
                            <div>
                                <span class="bold">Subtotal</span> ${{numberWithCommas(total)}}
                            </div>
                            <div>
                                <span class="bold">Envío</span> ${{numberWithCommas(envio)}}
                            </div>
                            <div>
                                <span class="bold">Total</span> ${{numberWithCommas(precioFinal)}}
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-btn block elevation="0" color="#93A898" tile to="/checkout" height="45px" :ripple="false">Proceder a la compra</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </template>
        <template v-else>
            <div style="min-height: calc(100vh - 232px)">
                <v-container>
                    <v-row align="center">
                        <v-col cols="auto">
                            <v-icon>mdi-basket-outline</v-icon>
                        </v-col>
                        <v-col>
                            <h1 class="d-inline-block">Cesta de compra</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            {{cart.length}} Productos
                        </v-col>
                    </v-row>
                </v-container>
            
                <v-container>
                    <v-row style="height: 300px" align="center">
                        <v-col class="text-center">
                            <span style="font-size: 2em">Tu cesta está vacía, comienza a agregar productos.</span>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div style="background-color: #EFECE8;">
                <v-container>
                    <v-row>
                        <v-col cols="12" class="text-center py-12">
                            <v-btn block color="#A06A4C" to="/tienda" height="45px">Ir a la tienda</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </template>

        <v-snackbar
            v-model="snackbarEliminado"
            top
            color="#722929"
        >
            Eliminado del carrito

            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                icon
                v-bind="attrs"
                @click="snackbarEliminado = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Vue from 'vue'
import VueSession from 'vue-session'
Vue.use(VueSession, {persist: true})
export default {
    props:{
        cart: Array,
        total: Number,
        envio: Number,
        precioFinal: Number
    },
    data () {
        return {
            snackbarEliminado: false
        }
    },
    beforeMount: function () {

    },
    methods: {
        numberWithCommas: function (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        removeItem: function (id) {
            let carrito = [];
            for (let index = 0; index < this.cart.length; index++) {
                if(index != id){
                carrito.push(this.cart[index]);
                } 
            }

            this.cart = carrito;
            this.snackbarEliminado = true;
            this.$session.set('emcart',this.cart);
            this.$emit('update');
        },
    }
}
</script>